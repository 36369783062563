<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 style="border:3px solid gold">
            <v-text-field dense label="Add Main Menu" outlined v-model="menuName"></v-text-field>
            <v-layout wrap justify-center>
              <v-flex align-self-center xs6 sm4 lg3>
                <v-btn outlined dense color="green" @click="addMain(menuName)">ADD</v-btn>
              </v-flex>
            </v-layout>
      </v-flex>
      <v-flex xs6 pt-3>
        <v-layout wrap>
          <v-flex xs12 px-5 v-for="(item, i) in menulist" :key="i">
            <v-card outlined style="border:2px solid red">

            <v-text-field label="Main_id" outlined v-model="item._id"></v-text-field>
            <v-text-field label="MAin_NAME" outlined v-model="item.name"></v-text-field>
            <v-text-field label="MAin_ORDER" outlined v-model="item.order"></v-text-field>
            <v-layout wrap justify-center>
              <v-flex align-self-center lg10 py-4>
                <v-btn @click="mainmenu(item._id,item.name,item.order)">Save</v-btn>
              </v-flex>

              <v-flex align-self-center lg12 pt-4 px-4 style="border:3px solid gold">
            <v-text-field outlined  label="Add SubName" v-model="item.names"></v-text-field>
              </v-flex>
              <v-flex align-self-center lg10 py-4>
                <v-btn @click="addsubmenu(item._id,item.names)">add</v-btn>
              </v-flex>
            </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6>
        <v-layout wrap v-if="flag">
          <v-flex xs12 px-5 v-for="(item, i) in sublist" :key="i">
            <v-card outlined style="border:2px solid green">
            <v-text-field label="SUB-id"  outlined v-model="item._id"></v-text-field>
            <v-text-field label="SUB-MENUID" outlined v-model="item.menu"></v-text-field>
            <v-text-field label="SUB-NAME" outlined v-model="item.name"></v-text-field>
            <v-text-field label="SUB-ROUTE" outlined v-model="item.route"></v-text-field>
            <v-text-field label="SUB-ICON" outlined v-model="item.icon"></v-text-field>
            <!-- <v-text-field label="SUB-ORDER" outlined v-model="item.order"></v-text-field> -->
            <v-layout wrap justify-center>
              <v-flex align-self-center lg10 py-4>
                <v-btn @click="submenu(item._id,item.menu,item.name,item.route,item.icon)">Save</v-btn>
              </v-flex>
              
              <v-flex align-self-center lg10 py-4>
                <v-btn @click="delsubmenu(item._id)" color="red">del</v-btn>
              </v-flex>
            </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      email: "",
      phone: "",
      password: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      flag: true,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      addcatt: false,
      editcatt: false,
      keyword: "",
      role: null,
      admins: [
        { name: "subadmin", label: "Sub Admin" },
        { name: "productadmin", label: "Product Admin" },
      ],

      json_fields: {
        PhoneNumber: "phone",
        Email: "email",
        Role: "role",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      roleRules: [(v) => !!v || "Role is required"],
      phnoRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length < 11 || "Invalid Phone",
        (v) =>
          /[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/.test(v) ||
          "Phone is invalid",
      ],
      rules: {
        required: (value) => !!value || "Required.",
        // min: (v) => v.length >= 8 || "Min 8 characters",
      },
      display: false,

      adminsdata: [],
      data: [],
      notify: false,
      editNotify: false,
      notifyId: null,
      checkbox: false,
      menus: [],
      menusUp: [],
      viewitem: [],
      viewdialog: false,
      about: [],
      menulist: [],
      sublist: [],
      names: '',
      menuName: '',
    };
  },
  mounted() {
    this.getData();
    this.getSub();
  },
  watch: {
    keyword() {
      this.getData();
    },
    currentPage() {
      this.getData();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    reCheck(item, check) {
      if (!check) {
        for (let i = 0; i < item.subMenus.length; i++) {
          item.subMenus[i].isChecked = false;
        }
      }
    },
    getdData() {
      this.editdialog = false;
      this.getData();
    },
    validURL(url) {
      var pattern =
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(([\w#!:.?+=&%@!]))?/;
      if (pattern.test(url)) {
        this.rules.mzg = "";
        return true;
      }
      this.rules.mzg = "Invalid URL";
      return false;
    },
    initialize() {
      this.admin = [];
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/menu/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        // params: {
        //   count: this.count,
        //   page: this.currentPage,
        //   keyword: this.keyword,
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.menulist = response.data.data;
          // this.json_data = this.adminsdata;
          // // this.name = this.guest.name;
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSub() {
      this.appLoading = true;
      axios({
        url: "/submenu/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        // params: {
        //   count: this.count,
        //   page: this.currentPage,
        //   keyword: this.keyword,
        // },
      })
        .then((response) => {
          this.appLoading = false;
          this.sublist = response.data.data;
          // this.json_data = this.adminsdata;
          // // this.name = this.guest.name;
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addMain(name) {
      this.appLoading=true
      axios({
        url: "/menu/add",
        method: "POST",
        data: {
          name: name,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.menuName=''
            this.getData();
            this.getSub();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    mainmenu(id,name,order) {
      this.appLoading=true
      axios({
        url: "/menu/edit",
        method: "POST",
        data: {
          id:id,
          name: name,
          order: order,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
            this.getSub();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    submenu(id,menu,name,route,icon) {
      this.appLoading=true
      axios({
        url: "/submenu/edit",
        method: "POST",
        data: {
          id:id,
          menu: menu,
          name: name,
          route: route,
          icon: icon,
          // order: order
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
            this.getSub();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addsubmenu(menu,name) {
      this.appLoading=true
      this.flag=false
      axios({
        url: "/submenu/add",
        method: "POST",
        data: {
          // id:id,
          menu: menu,
          name: name,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.flag=true
            this.getData();
            this.getSub();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    
    delsubmenu(id) {
      this.appLoading=true
      axios({
        url: "/submenu/remove",
        method: "POST",
        data: {
          id:id,
          // menu: menu,
          // name: name,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
            this.getSub();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.role = null;
      this.email = null;
      this.phone = null;
      this.notify = false;
      this.editNotify = false;
      // this.password = null;
      this.menus = [];
      this.about = [];
      this.dialog = false;
      this.editdialog = false;
      this.getData();
    },
    adminsdataadd() {
      var data = {};
      // data["role"] = this.role;
      data["email"] = this.email;
      data["phone"] = this.phone;
      data["password"] = this.password;
      axios({
        url: "/user/createadmin",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.notifyId = response.data.data._id;
            this.menus = response.data.menu;
            this.notify = true;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.role = null;
            this.email = null;
            this.phone = null;
            this.password = null;
            // this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addMenu() {
      this.appLoading = true;
      var data = {};
      data["id"] = this.notifyId;
      data["menu"] = this.menus;
      axios({
        url: "/user/udateadminrights",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.role = null;
            this.email = null;
            this.phone = null;
            this.password = null;
            this.menus = [];
            this.notify = false;
            this.editNotify = false;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // validateMenu(id) {
    //   let myFlag = false;
    //   // let not = [];
    //   // let yes = [];
    //   let allJ = 0;
    //   // // let allI=0
    //   let count = 0;
    //   let countt = 0;
    //   for (let i = 0; i < this.about.length; i++) {
    //     for (let j = 0; j < this.about[i].subMenus.length; j++) {
    //       if (this.about[i].isChecked) {
    //         if (this.about[i].subMenus[j].isChecked) {
    //           count++
    //           if(count>0){
    //             myFlag=true

    //           }
    //           // console.log("YES=");
    //           // break
    //           // allJ = j;
    //           // count++;
    //           // yes.push(this.about[i].subMenus[j].name);
    //         } else {
    //           myFlag=false
    //           countt++
    //           // not.push(this.about[i].subMenus[j].name);
    //           // console.log("Not selected=");
    //         }

    //       }

    //       if(count+countt !== allJ){
    //             console.log("j",allJ)
    //             console.log("crt",countt+count)
    //             myFlag=false
    //             // break
    //           }
    //     }

    //     // count=0
    //   }

    //   if (!myFlag) {
    //     console.log("OOPS");
    //   } else {
    //     console.log("Okk");
    //     // this.updateMenu(id);
    //   }
    //   console.log(id);
    // },
    updateMenu(id) {
      this.appLoading = true;
      var data = {};
      data["id"] = id;
      data["menu"] = this.about;
      axios({
        url: "/user/udateadminrights",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.role = null;
            this.email = null;
            this.phone = null;
            this.password = null;
            this.menus = [];
            this.about = [];
            this.editNotify = false;
            this.editdialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
      this.editGet(this.editingitem._id);
    },
    editGet(id) {
      this.appLoading = true;
      axios({
        url: "/user/adminget",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.about = response.data.menu;
          // console.log("sd", this.about);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      if (this.editingitem.email) {
        var data = {};
        if (this.editingitem.password) {
          data["id"] = this.editingitem._id;
          data["role"] = this.editingitem.role;
          data["email"] = this.editingitem.email;
          data["phone"] = this.editingitem.phone;
          data["password"] = this.editingitem.password;
        } else {
          data["id"] = this.editingitem._id;
          data["role"] = this.editingitem.role;
          data["email"] = this.editingitem.email;
          data["phone"] = this.editingitem.phone;
        }
        axios({
          url: "/user/editadmin",
          method: "POST",
          data: data,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            // this.editdialog = false;
            this.display = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = "Edited Sucessfully";
              this.showsnackbar = true;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    viewCat(item) {
      this.viewitem = item;
      this.viewdialog = true;
      this.editGet(this.viewitem._id);
    },
  },
};
</script>